<template>
  <mu-container class="threads-list" v-loading="loading" data-mu-loading-size="24">
      <mu-expansion-panel v-for="thread in threads" :key="thread.tid">
        <div slot="header">{{ thread.title }}</div>
        <p>发布者：{{ thread.author }}</p>
        <p>
          原帖链接：<a :href="thread.url">{{ thread.title }}</a>
          <mu-tooltip content="复制">
            <mu-button @click="copyTitle(thread.title, thread.format)" icon>
              <mu-icon value="content_copy" size="18"></mu-icon>
            </mu-button>
          </mu-tooltip>
        </p>
        <p>百度网盘：
          <mu-button v-if="!thread.baidupan" @click="getBaidupan(thread)">获取数据</mu-button>
          <a v-if="thread.baidupan" :href="thread.baidupan">{{ thread.baidupan }}</a>
          <span style="padding-left: 30px" v-if="thread.baidupan == false">获取信息失败</span>
        </p>
        <!-- 购物车 -->
        <mu-button slot="action" flat color="primary" @click='cart(thread)'>
          <template v-if='incart(thread)'>
          Remove
          </template>
          <template v-else>
          Add
          </template>
        </mu-button>
        <!-- 购物车 结束 -->
        <mu-button slot="action" flat>Copy</mu-button>
      </mu-expansion-panel>
    </mu-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ThreadsList',
  props: {
    threads: Array,
    loading: Boolean
  },
  computed: {
    ...mapState(['cartedThreads'])
  },
  methods: {
    getBaidupan: function (thread) {
      thread.baidupan = null
      const url = `${process.env.VUE_APP_API}/baidupan/tid/${thread.tid}`
      this.$axios
        .get(url)
        .then(response => {
          thread.baidupan = response.data.fullUrl
        })
        .catch(error => {
          console.log(error)
          thread.baidupan = false
        })
    },
    copyTitle: function (title, format) {
      console.log(this, title, format)
      let filename = ''
      if (format) {
        filename = `${title}.${format}`
      } else {
        filename = title
      }
      this.$copyText(filename)
        .then(function (e) {
          console.log(e)
        }, function (e) {
          console.log(e)
        })
    },
    // 购物车
    cart: function (thread) {
      this.$store.commit('cartOperator', thread)
    },
    incart: function (thread) {
      return this.cartedThreads.includes(thread)
    }
    // 购物车结束
  }
}
</script>

<style lang="stylus" scoped>
.threads-list
  margin-top 40px
</style>
